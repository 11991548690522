import React from "react"

import Layout from "../components/layout"

const IndexPage: React.SFC<{}> = () => (
  <Layout>
    <ul>
      <li>
        <a href="https://github.com/unosk">Github</a>
      </li>
      <li>
        <a href="https://qiita.com/unosk">Qiita</a>
      </li>
      <li>
        <a href="https://twitter.com/unosk_s">Twitter</a>
      </li>
    </ul>
  </Layout>
)

export default IndexPage
